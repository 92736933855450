<template>
  <v-container fluid>
    <v-stepper v-model='step'>
      <v-stepper-header>
        <v-stepper-step
          :complete='step > 1'
          step='1'
        >
          {{$t('INFORMATION')}}
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete='step > 2'
          step='2'
        >
          {{$t('LOCAL')}}
        </v-stepper-step>

        <v-divider />

        <v-stepper-step step='3'>
          {{$t('CONFIRMATION')}}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          step='1'
        >
          <DocumentType @document='setTypeDocument' />
          <v-form
            ref='formStock'
            v-model='validFormStock'
            class='form-container'
            lazy-validation
            :disabled='typeDocument === null'
          >
            <v-row>
              <v-col
                cols='12'
                md='6'
              >
                <v-text-field
                  v-model='discardedNumber'
                  :label='$t("DISCARDED_NUMBER")'
                  type='number'
                  :rules='discardedNumberRules'
                  :counter='10'
                  required
                />
                <v-text-field
                  v-model='discardTime'
                  :label='$t("DISCARD_HOUR")'
                  type='time'
                  :rules='discardTimeRules'
                  required
                />
                <v-text-field
                  v-model='serviceOrder'
                  :label='$t("SERVICE_ORDER")'
                  :rules='serviceOrderRules'
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols='12'>
                <v-btn
                  color='secondary'
                  class='mr-4'
                  :disabled='!validFormStock || typeDocument === null'
                  @click='validateStock'
                >
                  {{$t('NEXT')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step='2'>
          <v-stepper-items>
            <v-form
              ref='formAddress'
              v-model='validFormAddress'
              class='form-container'
              lazy-validation
            >
              <v-col
                cols='12'
                md='6'
              >
                <v-text-field
                  v-model='shippingZipCode'
                  :label='$t("ZIP_CODE")'
                  :rules='shippingZipCodeRules'
                  :counter='8'
                  required
                  @blur='findCep'
                />
                <v-text-field
                  v-model='destination'
                  :label='$t("DESTINATION")'
                  disabled
                  required
                />
                <v-text-field
                  v-model='district'
                  :label='$t("DESTRICT")'
                  disabled
                  required
                />
                <v-text-field
                  v-model='streetName'
                  :label='$t("STREET")'
                  disabled
                  required
                />
                <v-text-field
                  v-model='locationNumber'
                  :rules='localtionNumberRules'
                  :label='$t("NUMBER")'
                  type='number'
                  required
                />
                <v-text-field
                  v-model='complement'
                  :label='$t("COMPLEMENT")'
                />
              </v-col>
              <v-row>
                <v-col cols='12'>
                  <v-btn
                    class='mr-4'
                    @click='backStep()'
                  >
                    {{$t('PREVIOUS_STEP')}}
                  </v-btn>
                  <v-btn
                    color='secondary'
                    class='mr-4'
                    :disabled='!validFormAddress'
                    @click='validateAddress'
                  >
                    {{$t('NEXT')}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-items>
        </v-stepper-content>
        <v-stepper-content step='3'>
          <v-stepper-items>
            <v-row>
              <v-col cols='12'>
                <v-list>
                  <v-list-item>
                    <div class='title'>
                      {{$t('INFORMATION')}}
                    </div>
                  </v-list-item>
                  <v-list-item>
                    Número Descartado: {{discardedNumber}}
                  </v-list-item>
                  <v-list-item>
                    Hora do descarte: {{discardTime}}
                  </v-list-item>
                  <v-list-item>
                    {{$t('SERVICE_ORDER')}}: {{serviceOrder}}
                  </v-list-item>
                </v-list>
                <v-divider />
                <v-list-item>
                  <div class='title'>
                    {{$t("LOCAL")}}
                  </div>
                </v-list-item>
                <v-list-item>
                  {{$t('ZIP_CODE')}}: {{shippingZipCode}}
                </v-list-item>
                <v-list-item>
                  {{$t('DESTINATION')}}: {{destination}}
                </v-list-item>
                <v-list-item>
                  {{$t('DESTRICT')}}: {{district}}
                </v-list-item>
                <v-list-item>
                  {{$t('STREET')}}: {{streetName}}
                </v-list-item>
                <v-list-item>
                  {{$t('NUMBER')}}: {{locationNumber}}
                </v-list-item>
                <v-list-item>
                  {{$t('COMPLEMENT')}}: {{complement}}
                </v-list-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols='12'>
                <v-btn
                  class='mr-4'
                  @click='backStep()'
                >
                  {{$t('PREVIOUS_STEP')}}
                </v-btn>
                <v-btn
                  color='success'
                  class='mr-4'
                  :disabled='!validFormAddress && !validFormStock'
                  @click='openModalPassword'
                >
                  {{$t('SAVE')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-items>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <PasswordRequired v-if='showConfirmPassword' @password-success='registerStockDispend' @password-cancel='closeModalPassword' />
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';

  export default {
    name: 'StockEntry',
    components: {
      PasswordRequired: () => import('../../components/PasswordRequired/PasswordRequired'),
      DocumentType: () => import('../../components/DocumentType/DocumentType'),
    },
    data: function () {
      return {
        validFormAddress: true,
        validFormStock: true,
        step: 1,
        showConfirmPassword: false,
        district: '',
        streetName: '',
        complement: '',
        destination: '',
        locationNumber: '',
        discardTime: '',
        typeDocument: null,
        discardTimeRules: [
          (v) => !!v || this.$t('REQUIRED'),
        ],
        localtionNumberRules: [
          (v) => !!v || this.$t('REQUIRED'),
        ],
        discardedNumber: '',
        discardedNumberRules: [
          (v) => !!v || this.$t('REQUIRED'),
          (v) => v.length <= 10 || this.$t('FIELD_LENGHT', { value: 10 }),
          (v) => v.length >= 10 || this.$t('FIELD_LENGHT', { value: 10 }),
        ],
        shippingZipCode: '',
        shippingZipCodeRules: [
          (v) => !!v || 'CEP de envio é obrigatório',
          (v) => v.length <= 8 || this.$t('ZIP_CODE_LENGHT', { value: 8 }),
          (v) => v.length >= 8 || this.$t('ZIP_CODE_LENGHT', { value: 8 }),
        ],
        serviceOrder: '',
        serviceOrderRules: [
          (v) => !!v || this.$t('REQUIRED'),
        ],
      };
    },
    methods: {
      validateStock: function () {
        if (!this.$refs.formStock.validate()) {
          return;
        }
        this.nextStep();
      },
      setTypeDocument: function (document) {
        this.typeDocument = document;
      },
      validateAddress: function () {
        if (!this.$refs.formAddress.validate()) {
          return;
        }
        this.nextStep();
      },
      nextStep: function () {
        this.step++;
      },
      backStep: function () {
        this.step--;
      },
      openModalPassword: async function () {
        if (!this.$refs.formStock.validate() && !this.$refs.formAddress.validate()) {
          return;
        }
        this.showConfirmPassword = true;
      },
      closeModalPassword: function () {
        this.showConfirmPassword = false;
      },
      findCep: async function () {
        if (this.shippingZipCode.length < 8) {
          return;
        }
        this.$store.dispatch('loading/toggleLoading');
        try {
          const result = await axios({
            url: `/ws/${this.shippingZipCode}/json`,
            baseURL: 'https://viacep.com.br',
            method: 'GET',
            responseType: 'json',
          });
          this.destination = `${result.data.localidade} - ${result.data.uf}`;
          this.district = result.data.bairro;
          this.streetName = result.data.logradouro;
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      registerStockDispend: async function () {
        this.closeModalPassword();
        try {
          this.$store.dispatch('loading/toggleLoading');
          await axios({
            url: '/stock-discard',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            data: {
              sequenceDiscarted: this.discardedNumber,
              hourDiscarted: this.discardTime,
              serviceOrder: this.serviceOrder,
              shippingZipCode: this.shippingZipCode,
              district: this.district,
              streetName: this.streetName,
              complement: this.complement,
              locationNumber: Number(this.locationNumber),
              destination: this.destination,
              typeDocument: this.typeDocument,
            },
            withCredentials: true,
          });
          this.step = 1;
          this.$refs.formStock.reset();
          this.$refs.formAddress.reset();
          this.$store.dispatch('loading/toggleLoading');
          Swal.fire({
            icon: 'success',
            title: this.$t('SUCCESS'),
            text: this.$t('SUCCESS_TEXT'),
          });
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
    },
  };
</script>

<style scoped lang="scss">
.form-container {
  padding: 30px;
}
</style>
