<template>
  <v-container fluid>
    <v-stepper v-model='step'>
      <v-stepper-header>
        <v-stepper-step
          :complete='step > 1'
          step='1'
        >
          Importação
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete='step > 2'
          step='2'
        >
          Seleção de template
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete='step > 3'
          step='3'
        >
          Configurações e Impressão
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete='step > 4'
          step='4'
        >
          Confirmação Impressão
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step='1'>
          <Import
            :reload-data='reloadList'
            :active='step === 1'
            @selected='getSelectedImport'
            @template='setTemplate'
            @next='nextStep'
            @templates='setTemplates'
            @customerSelect='setCustomerSelect'
          />
        </v-stepper-content>
        <v-stepper-content step='2'>
          <!-- eslint-disable -->
          <Template
            v-if='step == 2'
            :selected='selectedImport'
            :templates='templates'
            @next='nextStep'
            @back='backStep'
            @template='setTemplate'
          />
        </v-stepper-content>
        <v-stepper-content step='3'>
          <Print
            v-if='step == 3'
            :selected='selectedImport'
            :template='template'
            @next='nextStep'
            @back='backStep'
            @firstSequence='saveFirstSequence'
            @selected='getSelectedImport'
            @linkRenach='setLinkRenach'
            @fieldToSave='setFieldToSave'
          />
        </v-stepper-content>
        <v-stepper-content step='4'>
          <Printed
            v-if='step == 4'
            :selected='selectedImport'
            :template='template'
            :sequence='firstSequence'
            :renach='linkRenach'
            :field='fieldToSave'
            :customer-select='customerSelect'
            @next='nextStep'
            @back='backStep'
            @selected='getSelectedImport'
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <PasswordRequired v-if='showConfirmPassword' @password-success='registerStockShipment' @password-cancel='closeModalPassword' />
  </v-container>
</template>

<script>
  export default {
    name: 'StockEntry',
    components: {
      PasswordRequired: () => import('../../components/PasswordRequired/PasswordRequired'),
      Import: () => import('./pages/Import'),
      Template: () => import('./pages/Template'),
      Print: () => import('./pages/Print'),
      Printed: () => import('./pages/EndPrint'),
    },
    data: function () {
      return {
        step: 1,
        showConfirmPassword: false,
        file: null,
        linkRenach: false,
        fieldToSave: '',
        search: '',
        template: '',
        selectedImport: [],
        headers: [
          { text: 'Nome', value: 'name' },
          { text: 'Data de Solicitação', value: 'requestDate' },
          { text: 'RENACH', value: 'renach' },
          { text: 'Documento Impresso', value: 'printed' },
          { text: 'Número espelho', value: 'mirrorNumber' },
        ],
        desserts: [],
        firstSequence: '',
        reloadList: false,
        templates: [],
        customerSelect: null,
      };
    },

    methods: {
      nextStep: function (step, reload) {
        if (!step) {
          this.step++;
        } else {
          this.step = step;
        }
        this.reloadList = reload;
      },
      backStep: function (step) {
        if (!step) {
          this.step--;
        } else {
          this.step = step;
        }
      },
      setTemplate: function (template) {
        this.template = template;
      },

      getSelectedImport: function (value) {
        this.selectedImport = value;
      },

      setLinkRenach: function (value) {
        this.linkRenach = value;
      },

      setFieldToSave: function (value) {
        this.fieldToSave = value;
      },

      saveFirstSequence: function (value) {
        this.firstSequence = value;
      },

      setTemplates: function (templates) {
        this.templates = templates;
      },

      setCustomerSelect: function (customerSelect) {
        this.customerSelect = customerSelect;
      },

      openModalPassword: async function () {
        if (!this.$refs.formStock.validate() && !this.$refs.formAddress.validate()) {
          return;
        }
        this.showConfirmPassword = true;
      },
      closeModalPassword: function () {
        this.showConfirmPassword = false;
      },
      showConfirmModal: function () {
        Swal.fire({
          icon: 'warning',
          text: 'Deseja confirmar operação?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.nextStep();
          }
        });
      },
    },
  };
</script>

<style scoped lang="scss">
.form-container {
  padding: 30px;
}
</style>
