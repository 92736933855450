<template>
  <v-container fluid>
    <v-card
      color='white'
      min-height='200px'
      class='mb-6'
    >
      <v-form
        ref='form'
        v-model='valid'
        class='form-container'
        lazy-validation
      >
        <div class='title'>
          {{$t('STOCK_ENTRY_TITLE')}}
        </div>

        <v-row>
          <v-col
            cols='12'
            md='6'
          >
            <v-text-field
              v-model='coilNumber'
              :label='$t("COIL_NUMBER")'
              :rules='coilNumberRules'
              required
            />
            <v-text-field
              v-model='dateOfProduction'
              :label='$t("PRODUCTION_DATE")'
              type='date'
              :rules='dateOfProductionRules'
              required
            />
            <v-text-field
              v-model='serviceOrder'
              :label='$t("SERVICE_ORDER")'
              :rules='serviceOrderRules'
              required
            />
          </v-col>

          <v-col
            cols='12'
            md='6'
          >
            <v-text-field
              v-model='quantityProduced'
              :label='$t("QUANTITY_PRODUCED")'
              type='number'
              :rules='quantityProducedRules'
              required
            />
            <v-text-field
              v-model='initialSequence'
              :label='$t("INITIAL_SEQUENCE")'
              type='number'
              :rules='initialSequenceRules'
              :counter='10'
              required
            />
            <v-select
              v-model='documentType'
              :items='itemsDocument'
              label='Tipo de documento'
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12'>
            <v-btn
              color='success'
              class='mr-4'
              :disabled='!valid'
              @click='openModalPassword'
            >
              {{$t('SAVE')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <PasswordRequired v-if='showConfirmPassword' @password-success='registerStock' @password-cancel='closeModalPassword' />
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { mapGetters } from 'vuex';

  export default {
    name: 'StockEntry',
    components: {
      PasswordRequired: () => import('../../components/PasswordRequired/PasswordRequired'),
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
      getUserId: function () {
        if (!this.getUser) {
          return undefined;
        }
        return this.getUser.account.id;
      },
    },
    data: function () {
      return {
        valid: true,
        showConfirmPassword: false,
        documentType: null,
        coilNumber: '',
        coilNumberRules: [
          (v) => !!v || this.$t('REQUIRED'),
        ],
        dateOfProduction: '',
        dateOfProductionRules: [
          (v) => !!v || this.$t('REQUIRED'),
        ],
        serviceOrder: '',
        serviceOrderRules: [
          (v) => !!v || this.$t('REQUIRED'),
        ],
        quantityProduced: 0,
        quantityProducedRules: [
          (v) => !!v || this.$t('REQUIRED'),
        ],
        initialSequence: 0,
        initialSequenceRules: [
          (v) => !!v || this.$t('REQUIRED'),
          (v) => v.length <= 10 || this.$t('FIELD_LENGHT', { value: 10 }),
          (v) => v.length >= 10 || this.$t('FIELD_LENGHT', { value: 10 }),
        ],
        itemsDocument: [],
      };
    },
    mounted: function () {
      this.getAllType();
    },
    methods: {
      openModalPassword: async function () {
        if (!this.$refs.form.validate()) {
          return;
        }
        this.showConfirmPassword = true;
      },
      closeModalPassword: function () {
        this.showConfirmPassword = false;
      },
      registerStock: async function () {
        this.closeModalPassword();
        try {
          this.$store.dispatch('loading/toggleLoading');
          await axios({
            url: '/stock',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            data: {
              accountId: this.getUserId,
              numCoil: this.coilNumber,
              productionDate: this.dateOfProduction,
              quantityProduced: Number(this.quantityProduced),
              initialSequence: Number(this.initialSequence),
              serviceOrder: this.serviceOrder,
              documentType: this.documentType,
            },
            withCredentials: true,
          });
          this.$refs.form.reset();
          this.$store.dispatch('loading/toggleLoading');
          Swal.fire({
            icon: 'success',
            title: this.$t('SUCCESS'),
            text: this.$t('SUCCESS_TEXT'),
          });
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      getAllType: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/stock-type',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.itemsDocument = result.data;
          console.log('Result', result); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
    },
  };
</script>

<style scoped lang="scss">
.form-container {
  padding: 30px;
}
</style>
