<template>
  <v-card id='print'>
    <v-card-title>
      <v-row>
        <v-col cols='4' sm='12' md='4'>
          <v-text-field
            v-model='search'
            append-icon='mdi-magnify'
            label='Buscar'
            single-line
            hide-details
          />
        </v-col>
        <v-col cols='4' sm='12' md='4'>
          <v-text-field
            v-model='initialDate'
            label='Data Inicial'
            type='date'
          />
        </v-col>
        <v-col cols='4' sm='12' md='4'>
          <v-text-field
            v-model='finalDate'
            label='Data Final'
            type='date'
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols='4' sm='12' md='4'>
          <v-btn
            color='secondary'
            class='mr-4'
            @click='getReports'
          >
            {{$t('SEARCH')}}
          </v-btn>
          <v-btn
            v-if='data.losses'
            color='secondary'
            class='mr-4'
            @click='openModalPassword'
          >
            Download
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if='data.losses'>
        <v-col cols='3' sm='12' md='3'>
          {{$t('TOTAL_LOSSES')}}: <strong>{{data.losses}}</strong>
        </v-col>
        <v-col cols='3' sm='12' md='3'>
          {{$t('PERCENTAGE_LOSSES')}}: <strong>{{data.percentageLosses}}%</strong>
        </v-col>
        <v-col cols='3' sm='12' md='3'>
          {{$t('TOTAL_PRODUCED')}}: <strong>{{data.produced}}</strong>
        </v-col>
        <v-col cols='3' sm='12' md='3'>
          {{$t('AVAILABLE_STOCK')}}: <strong>{{data.quantity}}</strong>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      id='printable'
      :headers='headers'
      :items='desserts'
      :search='search'
      :disable-pagination='disablePagination'
    >
      <template #item.discarted='{ item }'>
        {{item.discarted ? 'Sim' : 'Não'}}
      </template>
      <template #item.discarted_date='{ item }'>
        {{formatDate(item.discarted_date)}}
      </template>
      <template #item.departure_date='{ item }'>
        {{formatDate(item.departure_date)}}
      </template>
    </v-data-table>
    <PasswordRequired v-if='showConfirmPassword' @password-success='downloadPdf' @password-cancel='closeModalPassword' />
  </v-card>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { jsPDF } from 'jspdf';

  export default {
    name: 'Reports',
    components: {
      PasswordRequired: () => import('../../components/PasswordRequired/PasswordRequired'),
    },
    data: function () {
      return {
        search: '',
        initialDate: '',
        showConfirmPassword: false,
        disablePagination: false,
        finalDate: '',
        headers: [
          { text: 'Sequência', value: 'sequence' },
          { text: 'Data de saída', value: 'departure_date' },
          { text: 'Destino', value: 'destination' },
          { text: 'Descartado', value: 'discarted' },
          { text: 'Data de Discarte', value: 'discarted_date' },
        ],
        desserts: [],
        data: {},
      };
    },
    mounted: function () {
      this.getDate();
    },
    methods: {
      getReports: async function () {
        if (!this.initialDate || !this.finalDate) return;
        if (this.initialDate > this.finalDate) {
          this.showDateError();
          return;
        }

        this.$store.dispatch('loading/toggleLoading');

        try {
          const result = await axios({
            url: `/stock-report?initialDate=${this.initialDate}&finalDate=${this.finalDate}`,
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.desserts = result.data.report.reportList;
          this.data = result.data.report;
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      showDateError: function () {
        Swal.fire({
          icon: 'error',
          title: this.$t('WARNING'),
          text: this.$t('INITIAL_FINAL_DATE'),
        });
      },

      openModalPassword: async function () {
        this.showConfirmPassword = true;
      },

      closeModalPassword: function () {
        this.showConfirmPassword = false;
      },

      getDate: function () {
        const date = new Date();
        const [year, month] = date.toISOString().split('T')[0].split('-');
        const [lastYear, lastMonth, lastDay] = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0].split('-');

        this.initialDate = `${year}-${month}-01`;
        this.finalDate = `${lastYear}-${lastMonth}-${lastDay}`;
      },

      formatDate: function (date) {
        if (!date) return null;

        const [year, month, day] = date.split('T')[0].split('-');
        return `${day}/${month}/${year} ${new Date(date).getHours().toString().padStart(2, '0')}:${new Date(date).getMinutes().toString().padStart(2, '0')}`;
      },

      downloadPdf: function () {
        this.closeModalPassword();
        this.$store.dispatch('loading/toggleLoading');
        this.search = '';
        this.disablePagination = true;
        // eslint-disable-next-line new-cap
        const doc = new jsPDF('p', 'pt', 'a4');
        const table = window.document.getElementsByTagName('table')[0];
        const cols = table.getElementsByTagName('td');
        const ths = table.getElementsByTagName('th');

        setTimeout(() => {
          cols.forEach((col) => {
            col.style.fontSize = '10px';
            col.style.width = '104.4px';
            col.style.textAlign = 'left';
          });

          ths.forEach((th) => {
            th.style.fontSize = '10px';
            th.style.width = '104.4px';
            th.style.textAlign = 'left';
          });

          doc.html(
            table,
            {
              x: 10,
              y: 10,
              width: 522,
            },
          );

          setTimeout(() => {
            try {
              doc.save('Relatório');
              Swal.fire({
                icon: 'success',
                title: this.$t('SUCCESS'),
                text: this.$t('DOWNLOAD_SUCCESS'),
              });
              this.$store.dispatch('loading/toggleLoading');
            } catch (e) {
              Swal.fire({
                icon: 'error',
                title: this.$t('WARNING'),
                text: this.$t('DOWNLOAD_ERROR'),
              });
              this.$store.dispatch('loading/toggleLoading');
            }
            this.disablePagination = false;

            cols.forEach((col) => {
              col.style.fontSize = '';
            });

            ths.forEach((th) => {
              th.style.fontSize = '';
            });
          }, 2000);
        }, 100);
      },
    },
  };
</script>

<style scoped lang="scss">
.form-container {
  padding: 30px;
}
@media print {
  body * {
    visibility: hidden;
    font-size: 20px !important;
  }

  #print, #print * {
    visibility: visible;
    border-bottom: none;
  }

  #title {
    visibility: hidden;
  }
}
</style>
